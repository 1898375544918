const state = {
    // Paste triggers btn loader
    pasteLoading: false,
    // Check if clipboard empty
    isSelectedCopyTriggers: false,
    // Copy from artwork trigger data
    triggersCopyData: null,
    // Artwork id's to paste triggers to
    selected: [],
}

const mutations = {  
    ADD_SELECTED (state, payload) {
        if(payload === []){
            state.selected = []
        }
        if(state.selected.filter(item => item.id === payload.id).length > 0){
            state.selected = state.selected.filter(item => item.id !== payload.id)
        } else {
            state.selected.push(payload)
        }
    },
    REMOVE_SELECTED (state, payload) {
        state.selected = state.selected.filter(item => item !== payload)
    },
    SET_CLIPBOARD (state, payload) {
        if(!state.isSelectedCopyTriggers){
            // Assigning an empty object before inserting data (just in case)
            state.triggersCopyData = {}
            // Copying triggers from artwork to respective variable for further work
            state.triggersCopyData = payload
            // Clear array to remove id of the artwork where copying triggers from
            state.selected = []
            // Set selected for copy to true
            state.isSelectedCopyTriggers = true
        }
    },
    TOGGLE_IS_SELECTED (state){
        state.isSelectedCopyTriggers = !state.isSelectedCopyTriggers
    },
    TOGGLE_PASTE_LOADER(state, payload){
        state.pasteLoading = payload
    },
    RESET_CLIPBOARD(state){
        state.isSelectedCopyTriggers = false
        state.pasteLoading = false
        state.triggersCopyData = null
        state.selected = []
    }
}

const actions = {
    setClipboard (context, payload) {
        context.commit('SET_CLIPBOARD', payload)
    },
    addSelected(context, payload) {
        context.commit('ADD_SELECTED', payload)
   },
    toggleSelected(context) {
        context.commit('TOGGLE_IS_SELECTED')
   },
    toggleLoader(context, payload) {
        context.commit('TOGGLE_PASTE_LOADER', payload)
   },
    emptyClipboard(context){
        context.commit('RESET_CLIPBOARD')
   }
}

const getters = {
    selected: state => {
        return state.selected;
    },
    pasteLoading: state => {
        return state.pasteLoading;
    },
    isSelectedCopyTriggers: state => {
        return state.isSelectedCopyTriggers;
    },
    triggersCopyData: state => {
        return state.triggersCopyData;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}