// Import UserController at the top of your Vuex file
import UserController from '@/services/controllers/User';

const state = {
  userBookmarks: null,
};

const mutations = {
  setUserBookmarks(state, userBookmarks) {
    state.userBookmarks = userBookmarks;
  },
  deleteUserBookmark(state, bookmarkId) {
    state.userBookmarks = state.userBookmarks.filter(bookmark => bookmark.id.toString() !== bookmarkId.toString());
    updateUserSettings(state.userBookmarks);
  },
  addUserBookmark(state, newBookmark) {
    if (this.userBookmarks == null ||!state.userBookmarks.some(b => b.id.toString() === newBookmark.id.toString())) {
      state.userBookmarks.push(newBookmark);
      updateUserSettings(state.userBookmarks);
    }
  },
};

const actions = {
  async updateUserBookmarks({ commit }, userBookmarks) {
    try {
      if (userBookmarks == null) {
        const res = await UserController.getUserBookmarks(false);
        userBookmarks = res.data;
      }
      commit('setUserBookmarks', userBookmarks);
    } catch (error) {
      console.error('Error fetching user bookmarks:', error);
      // Handle error (e.g., commit an error mutation or show a user-friendly message)
    }
  },
  async deleteUserBookmark({ commit, state }, bookmarkId) {
    try {
      commit('deleteUserBookmark', bookmarkId);
    } catch (error) {
      console.error('Error deleting user bookmark:', error);
      // Handle error if needed
    }
  },
  async addUserBookmark({ commit, state }, newBookmark) {
    try {
      commit('addUserBookmark', newBookmark);
    } catch (error) {
      console.error('Error adding user bookmark:', error);
      // Handle error if needed
    }
  },
};

const getters = {
  getUserBookmarks: (state) => state.userBookmarks,
  getBookmarkById: (state) => (bookmarkId) => state.userBookmarks.find((bookmark) => bookmark.id === bookmarkId),
};

export default {
  state,
  mutations,
  actions,
  getters,
};

// Helper function to update user settings in UserController
async function updateUserSettings(userBookmarks) {
  // Convert userBookmarks to a string list of IDs, or null if userBookmarks is null
  var userBookmarkIds = userBookmarks == null ? null : userBookmarks.map(b => b.id).join(',');
  console.log('Updating user settings with bookmark IDs:', userBookmarkIds);
  try {
    // get the data value from await UserController.setUserBookmarks(userBookmarkIds, false);
    await UserController.setUserBookmarks(userBookmarkIds, false).then(res => {
      return res.data.updatedBookmarks;
    });
    
  } catch (err) {
    // Handle error if needed
    console.error('Error updating user settings:', err);
  }
}