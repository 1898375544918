<template v-if="$store.state.Permissions.userOnlineStatus">
  <transition v-if="activeUsers" name="fade" class="profile-container">
    <transition-group v-if="activeUsers && activeUsers.length > 0" name="list" tag="ul" class="profile-list">
      <li
        v-for="(user) in activeUsers.slice(0, navmaxitems)"
        :key="user.userId"
        class="profile-item profile-item-tooltip">
        <img :style="{
          transition: 'all 250ms ease',
          margin:'-3px',
          border: user.browserState ? 'solid grey' : 'solid green',
          filter: user.browserState ? 'grayscale(1)' : 'grayscale(0)',
          opacity: user.browserState ? '0.5' : '1',
        }" :src="user.profilePicture ? user.profilePicture : 'contact-placeholder.jpg'" style="width:41px;height:41px;object-fit: cover;border-radius: 100%;"/>
        <span class="profile-item-tooltip-text v-tooltip__content" style="color: white">{{ user.fullName }}</span>
      </li>
      <div
        v-if="activeUsers.length - navmaxitems > 0"
        key="dropdown"
        class="profile-item dropdown-item-tooltip"
        style="text-align: center;padding-top: 7px;font-weight: bold;color: grey;border: solid green;"
      >
        <p>+{{ activeUsers.length - navmaxitems }}</p>
        <div class="dropdown-item-tooltip-text v-tooltip__content">
          <table>
            <tr v-for="(user) in activeUsers.slice(navmaxitems)" :key="user.userId">
              <td>
                <div
                  style="background: white; height: 12px;width: 12px;border-radius: 100%;margin-top:-3px"
                  :style="{ background: user.browserState ? 'grey' : 'green' }"
                ></div>
              </td>
              <td style="width:max-content;text-align: left;padding-left: 6px;">{{ user.fullName }}</td>
            </tr>
          </table>
        </div>
      </div>
    </transition-group>
  </transition>
</template>

<script>
import { signalr } from '@/main.js';
import UserController from '@/services/controllers/User'
export default {
  props: ['navmaxitems'],
  data() {
    return {
      activeUsers: null,
      allUsers: null,
      updateRate: 2000,
      latestUrlPath: null,
    };
  },
  async mounted() {
    
    // Checks the users browser state
    this.checkActivity();

    // Check if user can view active users
    if(this.$store.state.Permissions.userOnlineStatus){
      // GET USER DETAILS
      await UserController.getUsers().then((res) => {
            this.allUsers = res.data
          })
      this.userDirectoryListener();
    }
    
    // Set up a callback for state changes
    signalr.onStateChange((newState, error) => {
      switch(newState) {
        case "Connected":
        case "Reconnected":
          this.userDirectoryListener();
          this.activeDirectoryTracker(this.urlFilters(), true);
          console.log('Reruning to SignalR to send active directory');
          break;
        default:
          this.activeUsers = null;
          console.log('Cant connect to SignalR to send active directory');
      }
    });


    // Start the connection
    this.activeDirectoryTracker(this.urlFilters());
  },
  watch: {
    $route: {
      handler() {
        let routeQuery = this.urlFilters();
        if(routeQuery !== this.latestUrlPath) {
          // Clear the previous timeout, if any
          clearTimeout(this.timeoutId);
          this.activeUsers = null;
          // Set a new timeout to wait for 2 seconds before invoking the connection
          this.timeoutId = setTimeout(() => {
            this.activeDirectoryTracker(routeQuery);
          }, this.updateRate);
        }
      },
      deep: true,
    },
  },
  methods: {
    activeDirectoryTracker(route, forceUpdate = false) {
        // Logic to handle route change
        if (forceUpdate ||this.latestUrlPath !== route) {
          signalr.connection.invoke('ActiveDirectory', route)
          .then(() => {
                this.latestUrlPath = route;
              })
          .catch((error) => {
            console.error(`Error sending User ActiveDirectory: ${error}`);
          });

        }
    },
    // Takes in this.$route, will return a filtered string version of this.$route.fullPath with removed queries ('tab'), the route itself should not be effected
    urlFilters() {
  let route = this.$route;
  let queryParams = Object.assign({}, route.query); // Copy the query parameters

  // Remove the 'tab' query parameter if it exists
  if ('tab' in queryParams) {
    delete queryParams.tab;
  }

  // Create a new object with the updated query parameters
  let filteredQuery = Object.keys(queryParams).length > 0 ? { ...queryParams } : null;

  // Create a new route object with the filtered query parameters
  let filteredRoute = {
    ...route,
    query: filteredQuery,
  };

  // Generate the filtered fullPath
  let filteredFullPath = this.$router.resolve(filteredRoute).href;

  return filteredFullPath;
},

    getValidUsersDetails(allUsers, validUsers) {
    const validUserDetails = [];

    //Turn validUsers into an object
    if (validUsers) {
      validUsers.forEach(validUser => {
        const userId = validUser.userId;
        const browserState = validUser.browserState;

        // exclude any user where .user is null
        const user = allUsers.find(user => user.user !== null && user.user.id === parseInt(userId));

        if (user) {
            const userId = user.user.id;
            const fullName = `${user.contactCard.firstName} ${user.contactCard.surname}`;
            const profilePicture = user.contactCard.profilePicture ? user.contactCard.profilePicture.urlThumb : null;

            validUserDetails.push({
                userId,
                fullName,
                profilePicture,
                browserState
            });
        }
    });

    //dev **
    // validUserDetails.push(...validUserDetails);
    // validUserDetails.push(...validUserDetails);
    // validUserDetails.push(...validUserDetails);


    return validUserDetails;
    }
},
    async userDirectoryListener() {
      if(this.$store.state.Permissions.userOnlineStatus) {
        // Active Directory Listener
        signalr.connection.on('ActiveDirectoryResponse', (activeUsers) => {
          this.activeUsers = this.getValidUsersDetails(this.allUsers, activeUsers);
        });
      }
    },
    async checkActivity() {
    var hidden = "hidden";
    console.log("Checking activity");
    var previousVisibilityState = "onload";
    // Standards:
    if (hidden in document)
      document.addEventListener("visibilitychange", onchange);
    else if ((hidden = "mozHidden") in document)
      document.addEventListener("mozvisibilitychange", onchange);
    else if ((hidden = "webkitHidden") in document)
      document.addEventListener("webkitvisibilitychange", onchange);
    else if ((hidden = "msHidden") in document)
      document.addEventListener("msvisibilitychange", onchange);
    // IE 9 and lower:
    else if ("onfocusin" in document)
      document.onfocusin = document.onfocusout = onchange;
    // All others:
    else
      window.onpageshow = window.onpagehide
      = window.onfocus = window.onblur = onchange;

      function onchange(evt) {
    var v = "visible", h = "hidden",
        evtMap = {
            focus: v, focusin: v, pageshow: v, blur: h, focusout: h, pagehide: h
        };

    evt = evt || window.event;
    var visibilityState = evt.type in evtMap ? evtMap[evt.type] : (this[hidden] ? "hidden" : "visible");
    var visibilityValue = visibilityState === "visible" ? 0 : 1;

    if (previousVisibilityState !== visibilityState) {
        previousVisibilityState = visibilityState;
        signalr.connection.invoke('UpdateBrowserState', visibilityValue)
            .catch((error) => {
                console.error(`Error sending state: ${error}`);
            });
    }
}
    // set the initial state (but only if the browser supports the Page Visibility API)
    if (document[hidden] !== undefined)
      onchange({ type: document[hidden] ? "blur" : "focus" });
  },
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 250ms ease; /* Add necessary transition properties */
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  margin-left: -43px !important;
  opacity: 0;
  transform: scale(0.4);
}

.fade-enter-active, .fade-leave-active {
  transition: all 250ms ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.profile-container {
  height: 41px;
  border-radius: 48px;
  display: inline-block;
  overflow-x: hidden;
  width: 100%;
  transition: opacity 250ms ease-out;
}

.profile-list {
  display: flex;
  flex-direction: row-reverse;
  overflow-x: hidden;
  border-radius: 25px;
}

.profile-item {
  flex: 0 0 auto;
  width: 41px;
  height: 41px;
  border-radius: 45px;
  border: 3px solid transparent;
  filter: brightness(1) grayscale(0);
  min-width: 41px;
  margin-right: 2px;
  margin-left: 2px;
  background-size: cover;
  transition: all 250ms ease;
  display: inline-block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profile-container::-webkit-scrollbar {
  display: none;
}

.profile-item-tooltip {
  position: relative;
  display: inline-block;
}

.profile-item-tooltip .profile-item-tooltip-text {
  visibility: hidden;
  text-align: center;
  min-width: 120px;
  border-radius: 6px;
  padding: 5px 0;
  opacity: 0;
  transition: opacity 100ms ease, visibility 100ms ease, transform 200ms ease;

  /* Position the tooltip */
  top: 45px;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
  transform: scale(0.6) translateY(-15px);
}

.profile-item-tooltip:hover .profile-item-tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(2px);
  font-weight: 500;
  padding: 6px 0px;
}

.profile-item-tooltip .profile-item-tooltip-text:hover {
  opacity: 0;
  transform: scale(0.6) translateY(-15px);
  visibility: hidden;
}


.dropdown-item-tooltip:hover .dropdown-item-tooltip-text {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(2px);
  font-weight: 500;
}
.dropdown-item-tooltip {
  position: relative;
  display: inline-block;
}

.dropdown-item-tooltip .dropdown-item-tooltip-text {
  visibility: hidden;
  text-align: center;
  min-width: 120px;
  width: max-content;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 100ms ease, visibility 100ms ease, transform 200ms ease;

  /* Position the tooltip */
  top: 45px;
  left: 18%;
  margin-left: -60px;
  position: absolute;
  z-index: 1;
  transform: scale(0.6) translateY(-15px);
}

.dropdown-item-tooltip .dropdown-item-tooltip-text:hover {
  opacity: 0;
  transform: scale(0.6) translateY(-15px);
  visibility: hidden;
}
</style>