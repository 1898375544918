<template>
  <div class="tab-master">
    <transition name="fade">
      <v-btn v-if="$route.query.cid"
        :disabled="blockInput"
        style="margin-right:12px;"
        elevation="1"
        fab
        small
        @click="bookmarkCampaign()"
      >
        <v-icon v-if="bookmarkedCampaigns.some(campaign => campaign.id.toString() === $route.query.cid.toString())">mdi-bookmark</v-icon>
        <v-icon v-else>mdi-bookmark-outline</v-icon>
      </v-btn>
    </transition>

    <div style="min-width: 60px;max-width:100%;height:41px">

      <v-slide-group
        center-active
        show-arrows
        style="height: 41px;"
      >
        <v-slide-item
        v-for="campaign in bookmarkedCampaigns"
        class="tab-item"
        :key="campaign.id"
          v-slot="{toggle}"
        >
        <v-menu
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y
        transition="slide-y-transition"
        content-class="elevation-0"
        x="center"
        y="center"
        center
        >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          elevation="4"
          v-bind="attrs"
          v-on="on"
          width="41"
          height="41"
          x-small
          @click="toggle();openCampaign(campaign.id)" 
          style="border-radius: 5px"
          :style="campaign.id == $route.query.cid ? 'background-color: var(--v-primary-base);' : ''"
        >
        <img v-if="campaign.imgURL && campaign.imgURL.length > 0 && campaign.imgURL != 'd-clamp-holder.jpg'" class="tab-item-content" :src="campaign.imgURL ? campaign.imgURL : 'd-clamp-holder.jpg'" style="object-fit: cover;">
        <div v-else class="tab-item-content" style="padding-top: 6px;background-color: #dddddd;">
          <span class="font-weight-bold" style="font-size: 17px;color: #6d6d6d;">{{ campaign.name[0] }}{{ campaign.name[campaign.name.length - 1] }}</span>
        </div>
        </v-btn>
        <div style="height:20px"></div>
      </template>
        <div class="tab-tooltip v-tooltip__content text-center" style="cursor:unset !important; pointer-events: unset !important; margin-top:10px">
        <span class="tab-tooltip-text" style="margin-right: 18px" v-text="campaign.name"></span>
        <v-btn fab x-small class="elevation-0" style="
          background: none;
          position: absolute;
          right:0;
          top:3px;
          height: 23px;"
          @click="deleteBookmarkCampaign(campaign.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-menu>
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedTab: null,
      isOptional: true,
      blockInput: false,
      tabBroadcast: new BroadcastChannel('tab'),
    };
  },

  watch: {
    async '$route.query.cid' () {
      this.currentTabSelector();
      },
    bookmarkedCampaigns: {
      handler() {
        this.currentTabSelector();
      },
      deep: true,
    },

  },
  computed: {
    bookmarkedCampaigns() {
      return this.$store.getters.getUserBookmarks || [];
    },
    currentCampaign() {
      return this.$store.getters.getCampaign || {};
    }
  },
  created() {
    this.$store.dispatch('updateUserBookmarks', null);
  },
  mounted() {
    this.tabBroadcast.onmessage = (event) => {
      // check if anything is different
      if (this.bookmarkedCampaigns != event.R) {
        this.$store.dispatch('updateUserBookmarks', event.data);
        console.log('Broadcast received: ', event.data);
      }
      };
  },
  methods: {
    currentTabSelector() {
      const cid = this.$route.query.cid;
      if (cid) {
        const index = this.bookmarkedCampaigns.findIndex(campaign => campaign.id === cid);
        if (index !== -1) {
          this.selectedTab = index;
          this.isOptional = false;
          return;
        }
      }

      this.selectedTab = null;
      this.isOptional = true;
    },

    bookmarkCampaign() {
      if(!this.checkIfCampaignIsActive()){
        console.log('Vuex campaign is not synced');
        return;
      }
      const cid = this.$route.query.cid;

      if (this.bookmarkedCampaigns.some(campaign => campaign.id.toString() === cid.toString())) {
        this.$store.dispatch('deleteUserBookmark', cid);
        this.tabBroadcast.postMessage(this.$store.getters.getUserBookmarks);
      }
      else {
        const cname = this.$store.getters.getCampaign.name;
        if (cname) {
          this.$store.dispatch('addUserBookmark', { 
            id: cid, 
            name: cname,
            imgURL: this.$store.getters.getCampaign.logo && this.$store.getters.getCampaign.logo.urlThumb ? this.$store.getters.getCampaign.logo.urlThumb : 'd-clamp-holder.jpg'
          });
          this.tabBroadcast.postMessage(this.$store.getters.getUserBookmarks);
        }
      }

      this.currentTabSelector();
    },

    openCampaign(id) {
      if(!this.checkIfCampaignIsActive()){
        console.log('Vuex campaign is not synced');
        return;
      }
      this.$router.push({
        name: 'Campaign',
        query: { 
          cid: id, 
          tab: this.$store.getters.getCampaignTab },
      });
    },

    deleteBookmarkCampaign(id) {
      if(!this.checkIfCampaignIsActive()){
        console.log('Vuex campaign is not synced');
        return;
      }
      this.$store.dispatch('deleteUserBookmark', id);
      this.tabBroadcast.postMessage(this.$store.getters.getUserBookmarks);
    },
    async checkIfCampaignIsActive(){
      if (this.$route.query.cid && this.currentCampaign.id !== this.$route.query.cid) {
        return true;
      }
      return false;
    }
  },
};
</script>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 250ms ease; /* Add necessary transition properties */
  opacity: 1;
  transform: scale(1);
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: scale(0.4);
}
.fade-enter-active {
transition: opacity 250ms ease 250ms, transform 250ms ease 250ms, max-width 250ms ease; /* Add the delay only for the transform property */
}

.fade-leave-active {
transition: opacity 250ms ease, transform 250ms ease, max-width 250ms ease 250ms; 
}

.fade-enter-active, .fade-leave-active {
max-width: 50px !important;
opacity: 1;
}

.fade-enter, .fade-leave-to {
opacity: 0;
transform: scale(0.95);
max-width: 0px !important; /* Initial width for enter and leaving elements */
}
.btn-container {
height: min-content;
margin-top: 5px;
border-radius: 100%;
display: flex;
flex-direction: row-reverse;
}

.tab-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style-type: none;
  margin-left: -26px;
  margin-right: -2px;
}

.tab-item{
  flex: 0 0 auto;
  margin-right: 2px;
  margin-left: 2px;
  transition: all 250ms ease;
  display: inline-block;
  margin-left:2px;
  margin-right:2px;
  opacity: 1;
  transform: scale(1);
}
.tab-item-content{
  width: 33px;
  height: 33px;
  border-radius: 3px;
}

.tab-tooltip{
  width: max-content;
  min-width: 120px;
  position: relative;
}

.tab-tooltip-text{
  font-weight: 500;
}

.tab-master {
width: 100%;
height: 41px;
display: flex;
z-index: 10;
}
</style>