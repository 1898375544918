<template>
  <v-app>
    <!-- Uses vue router -->
    <router-view />
    <!-- Global snackbar -->
    <SnackBar />
  </v-app>
</template>

<script>
import SnackBar from '@/components/_theme/SnackBar.vue'
// Mixins
import localStorage from '@/mixins/local/storage'

export default {
  name: "App",

  mixins: [
    localStorage,
  ],

  components: {
    SnackBar
  },

  data() {
    return {
      isDarkMode: null,
    }
  },

  async created() {
    this.setDefaultTheme()
    this.setFavicon()
  },

  methods: {
    setDefaultTheme() {
      // if user is not logged in, set dark mode
      const user = this.getVueLocal("username")
      if(!user) {
        this.$vuetify.theme.dark = true
        this.setVueLocal('isDarkMode', true)
      } else { // else, set theme to user's preference 
        const night = this.getVueLocal(['isDarkMode'])
        this.$vuetify.theme.dark = JSON.parse(night)
        this.setVueLocal('isDarkMode', this.$vuetify.theme.dark)
      }
    },

    setFavicon() {
      document.getElementById("favicon");
    
      if(window.location.href.includes('deliver')) {
        document.title = 'Deliver'
        favicon.href = 'deliverFav.png';
      }
      else if(window.location.href.includes('verify')) {
        document.title = 'Verify'
        favicon.href = 'verifyFav.png';
      }
      else if(window.location.href.includes('local')) {
        document.title = 'Local'
        favicon.href = 'verifyFav.png';
      }
      else {
        document.title = 'Deliver'
        favicon.href = 'deliverFav.png';
      }
    }
  }
};
</script>

<style>
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
    border-radius: 10px;
    background-color: #363636;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #717171;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
    background-color: #717171;
  }
  .v-select .v-select__selections {
    text-transform: none !important;
  }
  .v-card--material .v-image .v-image__image {
      border-radius: 0px !important;
  }

  .mouse-pointer:hover {
    cursor: pointer;
  }

  .centered-input input {
    text-align: center;
  }
</style>