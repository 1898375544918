import PermissionControllers from '@/services/controllers/Permission'
import store from '@/store'
const update = async () => {
    const res = await PermissionControllers.getAllPermissions(
        {
            username: localStorage.getItem("username"),
            refreshToken: localStorage.getItem("refreshToken"),
        }
    )
    store.dispatch('Permissions/update', res.data)
    return res
}

export default update