import api from '@/services/api'
export default {
    getAllPermissions (data) {
        return api().post('Permission', data)
    },
    getBatchPermissions (data) {
        return api().get('Permission/IsAllowed/' + data)
    },
    getIndividualPermission (data) {
        return api().post('Permission/IsAllowed', data)
    },
}
