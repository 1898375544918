// src/plugins/analytics/index.js

import VueGtag from 'vue-gtag'

export default {
install(Vue, options) {
  // Analytics configuration per environment
  const GA_CONFIG = {
    local: {
      measurementId: 'G-ZTQLMDTSZN',
      appName: 'Deliver - Local',
      debugMode: true
    },
    stage: {
      measurementId: 'G-2HXLQJ774K',
      appName: 'Deliver - Stage',
      debugMode: true
    },
    production: {
      measurementId: 'G-42XJLRH3GR',
      appName: 'Deliver - Production',
      debugMode: false
    }
  }

  // Constants for event categories
  const EVENT_CATEGORIES = {
    USER: 'user_interaction',
    CAMPAIGN: 'campaign_interaction',
    SYSTEM: 'system_event',
    ERROR: 'error',
    NAVIGATION: 'navigation',
    SCROLL: 'scroll_tracking'
  }

  // Determine current environment
  const getCurrentEnvironment = () => {
    const hostname = window.location.hostname
    
    if (hostname.includes('localhost')) {
      return 'local'
    } else if (hostname.includes('stage.deliverdooh.com')) {
      return 'stage'
    } else if (hostname.includes('deliver.dooh.com')) {
      return 'production'
    }
    
    console.warn('Environment could not be determined, defaulting to local')
    return 'local'
  }

  // Simplified scroll tracking
  const initScrollTracking = () => {
    let scrollMarks = new Set()
    let timer = null
    const SCROLL_THRESHOLDS = [25, 50, 75] // GA4 handles 90% natively

    const handleScroll = () => {
      if (timer) clearTimeout(timer)
      
      timer = setTimeout(() => {
        const documentHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight
        const scrollPercentage = Math.round((window.scrollY / documentHeight) * 100)
        
        SCROLL_THRESHOLDS.forEach(threshold => {
          if (scrollPercentage >= threshold && !scrollMarks.has(threshold)) {
            scrollMarks.add(threshold)
            
            Vue.$gtag.event('scroll_depth', {
              event_category: EVENT_CATEGORIES.SCROLL,
              scroll_percentage: threshold,
              page_path: window.location.pathname,
              page_title: document.title,
              timestamp: new Date().toISOString()
            })
          }
        })
      }, 250)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    
    return {
      reset: () => scrollMarks.clear(),
      cleanup: () => window.removeEventListener('scroll', handleScroll)
    }
  }

  // Get current environment configuration
  const currentEnv = getCurrentEnvironment()
  const envConfig = GA_CONFIG[currentEnv]

  // Error tracking utility
  const trackError = (error, context = {}) => {
    Vue.$gtag.event('error', {
      error_name: error.name,
      error_message: error.message,
      error_stack: error.stack,
      error_context: context,
      event_category: EVENT_CATEGORIES.ERROR,
      timestamp: new Date().toISOString()
    })
  }

  // Performance tracking utility
  const trackTiming = (category, variable, value) => {
    Vue.$gtag.event('timing_complete', {
      event_category: category,
      event_label: variable,
      value: Math.round(value)
    })
  }

  const setUserProperties = (user) => {
    if (!user) return

    // Set user_id (this is a special parameter)
    Vue.$gtag.set({ 
      user_id: user.id 
    })

    // Set user properties using the correct GA4 command
    Vue.$gtag.event('set_user_properties', {
      user_properties: {
        role_id: user.roleId,
        role_name: user.roleName,
        stakeholder_id: user.stakeholderId,
        stakeholder_name: user.stakeholderName
      }
    })
    
    // Send login event separately
    Vue.$gtag.event('user_login', {
      event_category: EVENT_CATEGORIES.USER,
      user_id: user.id,
      role_id: user.roleId,
      role_name: user.roleName,
      stakeholder_id: user.stakeholderId,
      stakeholder_name: user.stakeholderName,
      last_login: new Date().toISOString()
    })
  }

  const sendPageView = (to, from, campaignData = null) => {
    const startTime = performance.now()
    const isTabChange = from && 
                       to.path === from.path && 
                       to.hash === from.hash &&
                       to.query.cid === from.query.cid;

    const pageData = {
      page_title: to.name,
      page_path: to.fullPath,
      page_location: window.location.href,
      timestamp: new Date().toISOString()
    }

    if (isCampaignRoute(to) && campaignData) {
      pageData.page_title = `${campaignData.name} - ${to.name}`
      pageData.campaign_id = campaignData.id
      pageData.campaign_name = campaignData.name
      pageData.campaign_status = campaignData.workflowStatus?.as
      pageData.view_type = to.query.tab || 'default'
    }

    if (isTabChange) {
      Vue.$gtag.event('tab_change', {
        event_category: EVENT_CATEGORIES.NAVIGATION,
        previous_tab: from.query.tab,
        new_tab: to.query.tab,
        page_path: to.fullPath,
        ...pageData
      });
    } else {
      Vue.$gtag.pageview(pageData)
    }

    const loadTime = performance.now() - startTime
    trackTiming('Page Load', to.name, loadTime)
  }

  // Helper functions
  const getCampaignIdFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
      return urlParams.get('cid')
    } catch {
      return null
    }
  }

  const isCampaignRoute = (route) => {
    return route.path.includes('/campaigns/campaign')
  }

  const waitForCampaign = (store, campaignId, maxWaitTime = 10000) => {
    return new Promise((resolve, reject) => {
      // First check if campaign already exists in store
      const currentCampaign = store.state.CurrentCampaign.campaign
      if (currentCampaign?.id === parseInt(campaignId)) {
        resolve(currentCampaign)
        return
      }

      // If not, watch for changes
      const unwatch = store.watch(
        state => state.CurrentCampaign.campaign,
        newCampaign => {
          if (newCampaign?.id === parseInt(campaignId)) {
            unwatch()
            resolve(newCampaign)
          }
        },
        { immediate: true }
      )

      // Optional timeout
      if (maxWaitTime) {
        setTimeout(() => {
          unwatch()
          reject(new Error(`Campaign ${campaignId} not found after ${maxWaitTime}ms`))
        }, maxWaitTime)
      }
    })
  }

  // Initialize Vue GTM
  Vue.use(VueGtag, {
    config: {
      id: envConfig.measurementId,
      params: {
        send_page_view: false,
        debug_mode: envConfig.debugMode,
        user_properties: true // Enable user properties
      }
    },
    appName: envConfig.appName,
    pageTrackerEnabled: false,
    enabled: true,
    bootstrap: true,
    onReady: () => {
      const user = options.store.state.User.user
      if (user) setUserProperties(user)
    }
  }, options.router)

  // Initialize scroll tracking
  let scrollTracker = initScrollTracking()

  // Enhanced event tracking methods
  Vue.prototype.$track = {
    userAction(action, details = {}) {
      try {
        Vue.$gtag.event(action, {
          event_category: EVENT_CATEGORIES.USER,
          timestamp: new Date().toISOString(),
          ...details
        })
      } catch (error) {
        trackError(error, { action, details })
      }
    },

    campaignAction(action, campaign, details = {}) {
      try {
        if (!isCampaignRoute(options.router.currentRoute)) return

        const urlCampaignId = getCampaignIdFromUrl()
        if (campaign.id === parseInt(urlCampaignId)) {
          Vue.$gtag.event(action, {
            event_category: EVENT_CATEGORIES.CAMPAIGN,
            campaign_id: campaign.id,
            campaign_name: campaign.name,
            campaign_status: campaign.workflowStatus?.as,
            timestamp: new Date().toISOString(),
            ...details
          })
        }
      } catch (error) {
        trackError(error, { action, campaign, details })
      }
    },

    systemEvent(action, details = {}) {
      Vue.$gtag.event(action, {
        event_category: EVENT_CATEGORIES.SYSTEM,
        timestamp: new Date().toISOString(),
        ...details
      })
    }
  }

  // Global error tracking
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (event) => {
      trackError(event.error, {
        type: 'window_error',
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno
      })
    })

    window.addEventListener('unhandledrejection', (event) => {
      trackError(event.reason, {
        type: 'unhandled_promise_rejection'
      })
    })
  }

  // Performance monitoring
  if (typeof window !== 'undefined' && window.performance) {
    window.addEventListener('load', () => {
      setTimeout(() => {
        const navigationTiming = performance.getEntriesByType('navigation')[0]
        if (navigationTiming) {
          trackTiming('Navigation', 'DOM Complete', navigationTiming.domComplete)
          trackTiming('Navigation', 'Load Complete', navigationTiming.loadEventEnd)
        }
      }, 0)
    })
  }

  // Campaign tracking state
  let currentCampaign = null

  // Watch for user changes
  options.store.watch(
    (state) => state.User.user,
    (newUser) => {
      if (newUser) setUserProperties(newUser)
    }
  )

  // Router tracking
  options.router.afterEach((to, from) => {
    if (scrollTracker) {
      scrollTracker.reset()
    }

    if (!isCampaignRoute(to)) {
      currentCampaign = null
      sendPageView(to, from)
      return
    }

    const urlCampaignId = getCampaignIdFromUrl()

    if (urlCampaignId) {
      waitForCampaign(options.store, urlCampaignId)
        .then(campaign => {
          currentCampaign = campaign
          sendPageView(to, from, currentCampaign)
        })
        .catch(() => {
          sendPageView(to, from)
        })
    } else {
      sendPageView(to, from)
    }
  })

  // Cleanup on app destruction
  if (typeof window !== 'undefined') {
    window.addEventListener('unload', () => {
      if (scrollTracker) {
        scrollTracker.cleanup()
      }
    })
  }
}
}
