// Vue & Vuex
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import UI from './ui/index'
import User from './user/index'
import UserBookmarks from './userBookmarks/index'
import Permissions from './permissions/index'
import Clipboard from './clipboard/index'
import PopGal from './pop-gal/index'
import Chart from './chart/index'
import CurrentCampaign from './currentCampaign/index'

// Init Vuex
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User, UserBookmarks, UI, Permissions, Clipboard, PopGal, Chart, CurrentCampaign
  },
})