import AuthenticationController from "@/services/controllers/Authentication";
import UserController from "@/services/controllers/User";
import axios from 'axios'
import store from '@/store'
import updateAllPermissions from '@/mixins/permissions/update'
// SIGNALR BLOCK //
import { signalr } from '@/main.js'

const refreshToken = async () => {
    let res = undefined
    let success = false
    let username = localStorage.getItem("username")
    let refreshToken = localStorage.getItem("refreshToken")

    if (refreshToken) {
        try {
            res = await AuthenticationController.authenticateRefresh({
                username: localStorage.getItem("username"),
                refreshToken: localStorage.getItem("refreshToken"),
            })

            axios.defaults.headers.common = {
                Authorization: `bearer ${res.data.token}`
            };

            localStorage.setItem("refreshToken", res.data.refreshToken);
            localStorage.setItem("username", res.data.email);

            await updateAllPermissions()

            store.dispatch('User/signIn', res.data)
            
            success = true

            // Update and start valid signalr connection
            // SIGNALR BLOCK //
            await signalr.start(res.data.token)
        }
        catch(err) {
            success = false
            if (err.response && err.response.data && err.response.data.forceLogout) {
                // LOGOUT BLOCK //
                // If forceLogout is true, force the user logout and close signalr connection
                await UserController.logout({
                    username: localStorage.getItem("username"),
                    refreshToken: localStorage.getItem("refreshToken"),
                  })

                localStorage.removeItem("username");
                localStorage.removeItem("refreshToken");

                // Redirect to login page If not on pop.dooh.com vue router#
                if (window.location.href.includes('pop.dooh.com') || window.location.href.includes('popstage.deliverdooh.com') || window.location.href.includes('pop-gallery')) {
                    $router.push({ name: 'PopGalleryLand' })
                }
                else{
                    this.$router.push({ name: 'Login' })
                }
            }
        }
    } 

    return { success, res }
}

export default refreshToken
