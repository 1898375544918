<template>
    <div class="editor-card" style="margin: 0px;padding: 0px;">
        <div class="editor-wrapper">
            <label v-if="!displayOnly" class="label">Demographic String</label>
            <div class="editor-container" :class="{ 'display-only': displayOnly }">
                <div v-if="!displayOnly" 
                     ref="editor" 
                     contenteditable="true" 
                     class="code-editor" 
                     :style="{ fontFamily: fontFamily + ', Monaco, monospace' }"
                     :class="{ 'has-error': errorText }" 
                     @input="handleInput" 
                     @keydown="handleKeydown"
                     spellcheck="false"></div>
                <div ref="highlight" 
                     class="code-highlight" 
                     :style="{ fontFamily: fontFamily + ', Monaco, monospace' }"
                     :class="{ 'display-only': displayOnly }"
                     aria-hidden="true">
                    <template v-for="(token, index) in tokens">
                        <span :key="index" :class="[
                            token.type,
                            {
                                'syntax-error': !hideHighlighting && (isErrorToken(token) || isInErrorRange(token)),
                                'syntax-warning': !hideHighlighting && isWarningToken(token)
                            }
                        ]">{{ token.value }}</span>
                    </template>
                </div>
            </div>
  
            <!-- Error Panel -->
            <div v-if="errorText && !hideMessages" class="message-panel error-panel">
                <div class="message-content">
                    <v-icon small color="#f44747" class="mr-2">
                        mdi-alert-circle
                    </v-icon>
                    <span class="error-text">{{ errorText }}</span>
                </div>
            </div>
  
            <!-- Warning Panel -->
            <div v-if="warnings.length > 0 && !hideMessages" class="message-panel warning-panel">
                <div v-for="(warning, index) in warnings" :key="index" class="message-content">
                    <v-icon small color="orange" class="mr-2">
                        mdi-alert
                    </v-icon>
                    <span class="warning-text">{{ warning.message }}</span>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import lexerWorkerService from './lexerWorkerService';
  
  export default {
    name: 'DemographicLexer',
  
    props: {
        value: {
            type: String,
            default: '(age=25 OR income>50000) AND (location="NYC")'
        },
        displayOnly: {
            type: Boolean,
            default: false
        },
        hideMessages: {
            type: Boolean,
            default: false
        },
        hideHighlighting: {
            type: Boolean,
            default: false
        },
        fontFamily: {
            type: String,
            default: 'Roboto'
        }
    },
  
    data() {
        return {
            inputText: '',
            tokens: [],
            errorText: '',
            errorPositions: [],
            parserError: null,
            warnings: []
        };
    },
  
    watch: {
        value: {
            handler(newValue) {
                if (this.displayOnly) {
                    this.processText(newValue);
                }
            },
            immediate: true
        }
    },
  
    created() {
        lexerWorkerService.initialize();
    },
  
    mounted() {
        if (!this.displayOnly) {
            this.$refs.editor.textContent = this.value;
        }
        this.processText(this.value);
    },
  
    beforeDestroy() {
        lexerWorkerService.release();
    },
  
    methods: {
        processText(text) {
            this.inputText = text;
            lexerWorkerService.process(text, this.handleWorkerMessage);
            if (!this.displayOnly) {
                this.$emit('input', text);
            }
        },
  
        handleWorkerMessage({ tokens, error, errorPositions, parserError, warnings }) {
            this.tokens = tokens;
            this.errorText = error || '';
            this.errorPositions = errorPositions || [];
            this.parserError = parserError;
            this.warnings = warnings || [];
        },
  
        handleInput(e) {
            const text = e.target.textContent;
            this.processText(text);
        },
  
        handleKeydown(e) {
            if (e.key === 'Tab') {
                e.preventDefault();
                document.execCommand('insertText', false, '    ');
            }
        },
  
        isErrorToken(token) {
            return token.type === 'error' || this.errorPositions.some(pos =>
                pos.start <= token.position && pos.end >= token.position + token.value.length
            );
        },
  
        isWarningToken(token) {
            return this.warnings.some(warning =>
                token.position >= warning.position.start &&
                token.position < warning.position.end
            );
        },
  
        isInErrorRange(token) {
            if (!this.parserError) return false;
  
            const { start, end } = this.parserError;
            const tokenStart = token.position;
            const tokenEnd = token.position + token.value.length;
  
            return (tokenStart >= start && tokenStart <= end) ||
                (tokenEnd >= start && tokenEnd <= end);
        }
    }
  };
  </script>
  
  <style>
  /* Syntax highlighting colors */
  .keyword {
    color: #c586c0;
  }
  
  .variable {
    color: #9cdcfe;
  }
  
  .number {
    color: #b5cea8;
  }
  
  .string {
    color: #ce9178;
  }
  
  .bracket {
    color: #ffd700;
  }
  
  .operator {
    color: #ff8c00;
  }
  
  .syntax-error {
    background-color: rgba(244, 71, 71, 0.3);
    border-bottom: 1px wavy #f44747;
  }
  
  .syntax-warning {
    background-color: rgba(255, 166, 0, 0.2);
    border-bottom: 1px wavy orange;
  }
  </style>
  
  <style scoped>
  .editor-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .label {
    color: #808080;
    font-size: 12px;
  }
  
  .monospace {
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
  }
  
  .editor-container {
    position: relative;
    background: #2d2d2d;
    border: 1px solid #3d3d3d;
    border-radius: 4px;
  }
  
  .editor-container:hover {
    border-color: #4d4d4d;
  }
  
  .editor-container:focus-within {
    border-color: #007acc;
  }
  
  .editor-container.display-only {
    border: none;
    background: transparent;
  }
  
  .code-editor {
    padding: 8px 12px;
    color: transparent;
    caret-color: white;
    background: transparent;
    outline: none;
    white-space: pre-wrap;
    overflow-y: auto;
    z-index: 1;
    position: relative;
  }
  
  .code-highlight {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 12px;
    color: #d4d4d4;
    white-space: pre-wrap;
    overflow-y: auto;
    pointer-events: none;
    background: transparent;
  }
  
  .code-highlight.display-only {
    position: relative;
    padding: 0;
    font-size: 13px;
  }
  
  .message-panel {
    margin-top: 8px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .message-content {
    padding: 8px 12px;
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  
  .error-panel {
    background: rgba(244, 71, 71, 0.1);
    border-left: 2px solid #f44747;
  }
  
  .warning-panel {
    background: rgba(255, 166, 0, 0.1);
    border-left: 2px solid orange;
  }
  
  .error-text,
  .warning-text {
    font-size: 12px;
    white-space: pre-wrap;
    flex-grow: 1;
  }
  
  .error-text {
    color: #f44747;
  }
  
  .warning-text {
    color: orange;
  }
  
  ::selection {
    background: rgba(255, 255, 255, 0.2);
  }
  </style>
  