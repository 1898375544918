import { render, staticRenderFns } from "./DemographicLexer.vue?vue&type=template&id=28c41777&scoped=true&"
import script from "./DemographicLexer.vue?vue&type=script&lang=js&"
export * from "./DemographicLexer.vue?vue&type=script&lang=js&"
import style0 from "./DemographicLexer.vue?vue&type=style&index=0&id=28c41777&prod&lang=css&"
import style1 from "./DemographicLexer.vue?vue&type=style&index=1&id=28c41777&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c41777",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
